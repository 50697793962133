import { differenceInDays, format, parse } from 'date-fns';
import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DatePicker from '../../presentation/components/Input/components/DatePicker/DatePicker';
import Answer from '../constants/Answer';
import Certificate from '../constants/Certificate';
import CheckLocation from '../constants/CheckLocation';
import CheckResult from '../constants/CheckResult';
import Incapacity from '../constants/Incapacity';
import Percentage from '../constants/Percentage';

export type Screen<T extends { [K in keyof T]?: string | undefined } = {}> = FC<
  RouteComponentProps<T>
>;

export interface ICheck {
  id: string;

  // Worker information
  workerFirstName: string;
  workerLastName: string;
  workerPhoneNumber?: string;
  companyCommercialName: string;

  // Doctor information
  doctorFirstName: string;
  doctorLastName: string;
  doctorMainLanguage: string;

  // Incapacity information
  checkDate: string;
  incapacityType: Incapacity;
  incapacityStartDate: string;
  incapacityEndDate?: string;
  incapacityPercentage: Percentage;
  incapacityValidatedToday?: boolean;
  certificateType: Certificate;

  // Check information
  location: CheckLocation;
  secondCheckNeeded: boolean;
  secondCheckDate?: string;
  secondCheckTime?: string;
  isSecondCheck: boolean;

  // Work accident information
  accidentDate?: string;
  accidentTime?: string;
  accidentDescription?: string;

  initialInjuryDescription: string;
  injuryDescription: string;
  injuryDifference: boolean;

  plausibility: Answer;

  // Result information
  result?: CheckResult;
  didWorkerShowUp: boolean;
  newIncapacityEndDate?: string;
  workerDisagreement: boolean;

  resultCommentMedicheck?: string;
  resultCommentWorker?: string;
  resultWorkerSignature?: string;
  secondCheckConvocation?: string;
  hasCompanyWhitelisting?: boolean;
  isWorkerWhitelisted?: boolean;

  // TODO: What does this mean?
  convocationShouldBeReported?: boolean;
}

export default class Check implements ICheck {
  id: string;

  workerFirstName: string;
  workerLastName: string;
  workerPhoneNumber?: string;
  companyCommercialName: string;

  checkDate: string;
  doctorFirstName: string;
  doctorLastName: string;
  doctorMainLanguage: string;

  incapacityType: Incapacity;
  incapacityStartDate: string;
  incapacityEndDate?: string;
  incapacityPercentage: Percentage;
  incapacityValidatedToday?: boolean;
  certificateType: Certificate;

  location: CheckLocation;
  secondCheckNeeded: boolean;
  secondCheckDate?: string;
  secondCheckTime?: string;
  isSecondCheck: boolean;

  accidentDate?: string;
  accidentTime?: string;
  accidentDescription?: string;

  initialInjuryDescription: string;
  injuryDescription: string;
  injuryDifference: boolean;

  plausibility: Answer;

  result?: CheckResult;
  didWorkerShowUp: boolean;
  newIncapacityEndDate?: string;
  workerDisagreement: boolean;

  resultCommentMedicheck?: string;
  resultCommentWorker?: string;
  resultWorkerSignature?: string;
  secondCheckConvocation?: string;
  hasCompanyWhitelisting?: boolean;
  isWorkerWhitelisted?: boolean;

  convocationShouldBeReported?: boolean;

  constructor(checkData: ICheck) {
    this.id = checkData.id;

    this.workerFirstName = checkData.workerFirstName;
    this.workerLastName = checkData.workerLastName;
    this.workerPhoneNumber = checkData.workerPhoneNumber;
    this.companyCommercialName = checkData.companyCommercialName;

    this.doctorFirstName = checkData.doctorFirstName;
    this.doctorLastName = checkData.doctorLastName;
    this.doctorMainLanguage = checkData.doctorMainLanguage;

    this.checkDate = checkData.checkDate;
    this.incapacityType = checkData.incapacityType;
    this.incapacityStartDate = checkData.incapacityStartDate;
    this.incapacityEndDate = checkData.incapacityEndDate;
    this.incapacityPercentage = Check.getDefaultValueIfNeeded(
      checkData.incapacityPercentage,
      Percentage.UNKNOWN
    );
    this.incapacityValidatedToday = checkData.incapacityValidatedToday; // No need for default value, we don't want to influence the doctor
    this.certificateType = Check.getDefaultValueIfNeeded(
      checkData.certificateType,
      Certificate.UNKNOWN
    );

    this.location = checkData.location;
    this.secondCheckNeeded = checkData.secondCheckNeeded;
    this.secondCheckDate = checkData.secondCheckDate;
    this.secondCheckTime = checkData.secondCheckTime;
    this.isSecondCheck = checkData.isSecondCheck;

    this.accidentDate = checkData.accidentDate;
    this.accidentTime = checkData.accidentTime;
    this.accidentDescription = checkData.accidentDescription;

    this.initialInjuryDescription = checkData.initialInjuryDescription;
    this.injuryDescription = checkData.injuryDescription;
    this.injuryDifference = checkData.injuryDifference;

    this.plausibility = Check.getDefaultValueIfNeeded(
      checkData.plausibility,
      Answer.UNKNOWN
    );

    this.result = checkData.result;
    this.didWorkerShowUp = checkData.didWorkerShowUp;
    this.newIncapacityEndDate = checkData.newIncapacityEndDate;
    this.workerDisagreement = checkData.workerDisagreement;

    this.resultCommentMedicheck = checkData.resultCommentMedicheck;
    this.resultCommentWorker = checkData.resultCommentWorker;
    this.resultWorkerSignature = checkData.resultWorkerSignature;
    this.secondCheckConvocation = checkData.secondCheckConvocation;
    this.hasCompanyWhitelisting = checkData.hasCompanyWhitelisting;
    this.isWorkerWhitelisted = checkData.isWorkerWhitelisted;

    this.convocationShouldBeReported = checkData.convocationShouldBeReported;
  }

  workerFullName() {
    return this.workerFirstName + ' ' + this.workerLastName;
  }

  doctorFullName() {
    return this.doctorFirstName + ' ' + this.doctorLastName;
  }

  /**
   * Checks wether or not the patient showed up to the check
   */
  didShowUp(): boolean {
    return !this.secondCheckNeeded;
  }

  /**
   * Checks wether or not the location is `HOME` and if this is the second check
   */
  isAtHome(): boolean {
    if (this.location === CheckLocation.HOME && !this.isSecondCheck) {
      return true;
    }

    return false;
  }

  /**
   * Checks wether or not the location is `OFFICE` and if this is the second check
   */
  isAtOffice(): boolean {
    if (
      this.location === CheckLocation.OFFICE ||
      this.location === CheckLocation.TELECHECK ||
      this.isSecondCheck
    ) {
      return true;
    }

    return false;
  }

  isInNoCertificatePeriod() {
    const difference = differenceInDays(
      Check.parseDate(this.checkDate),
      Check.parseDate(this.incapacityStartDate)
    );

    return difference < 2;
  }

  isWorkAccident() {
    return this.incapacityType === Incapacity.WORK_ACCIDENT;
  }

  isIllness() {
    return this.incapacityType === Incapacity.ILLNESS;
  }

  private static getDefaultValueIfNeeded(value: number, fallback: number) {
    if (value === -1) {
      return fallback;
    }

    return value;
  }

  static formatDate(
    date: string | number | Date,
    options?: {
      locale?: Locale;
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
      firstWeekContainsDate?: number;
      useAdditionalWeekYearTokens?: boolean;
      useAdditionalDayOfYearTokens?: boolean;
    }
  ) {
    return format(new Date(date), DatePicker.defaultDateMask, options);
  }

  static parseDate(
    date: string,
    options?: {
      locale?: Locale;
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
      firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
      useAdditionalWeekYearTokens?: boolean;
      useAdditionalDayOfYearTokens?: boolean;
    }
  ) {
    return parse(date, DatePicker.defaultDateMask, new Date(), options);
  }
}
